@use 'styles/new-design/main.scss';

.wiki-help-file {
  max-width: 100% !important;
  height: inherit;
  width: inherit;
  padding: var(--ds-spacing);

  img {
    display: block;
    max-width: 100%;
  }

  .si-mat-dialog-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    mat-dialog-content {
      max-height: fit-content !important;
      flex: 1 1 auto;
    }

    mat-dialog-actions {
      flex: 0 0 auto;
    }
  }
}

.spotlight-helper {
  position: relative;

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    box-sizing: border-box;
  }
}

.spotlight-text {
  white-space: pre-line;
}

.si {
  &-mat-form-field-icon {
    position: absolute;
    top: calc(-1.2 * var(--ds-spacing));
    right: 0;
    cursor: default;
    color: var(--ds-neutral-400);
  }

  &-strong-700 {
    font-weight: 700;
  }
}
